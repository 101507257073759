import * as API from "@/api/api";

const paramType = {
  // 版本更新查询
  version: "/version",
  uploadProblem: "/version/upload/problem",
  uploadLogs: "/version/upload/logs",
};

// 发送 GET 请求的函数
export const indexGet = (key, param) => {
  const url = paramType[key];
  return API.get(url, param);
};

// 发送 POST 请求的函数
export const indexPost = (key, param) => {
  const url = paramType[key];
  return API.post(url, param);
};

// 上传头像的函数
export const indexUpload = (key, formData) => {
  const url = paramType[key];
  return API.uploadImage(url, formData);
};
