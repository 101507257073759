import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import { useAuthStore } from "@/store/useAuthStore";

// 定义子路由数组以提高可读性和可维护性
const authRoutes = [
  {
    path: "login",
    name: "auth-login",
    component: () => import("@/views/Auth/LoginView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "admin/login",
    name: "auth-admin",
    component: () => import("@/views/Auth/LoginAdminView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "register",
    name: "auth-register",
    component: () => import("@/views/Auth/RegisterView.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "box",
    name: "auth-box",
    component: () => import("@/views/Auth/BoxView.vue"),
    meta: { requiresAuth: true },
  },
];

const meetingRoutes = [
  {
    path: "main",
    name: "main",
    component: () => import("@/views/Meeting/MainView.vue"),
  },
  {
    path: "initiate",
    name: "initiate",
    component: () => import("@/views/Meeting/InitiateView.vue"),
  },
  {
    path: "reservation",
    name: "reservation",
    component: () => import("@/views/Meeting/ReservationView.vue"),
  },
  {
    path: "fast",
    name: "fast",
    component: () => import("@/views/Meeting/FastView.vue"),
  },
  {
    path: "join",
    name: "join",
    component: () => import("@/views/Meeting/JoinView.vue"),
  },
  {
    path: "share",
    name: "share",
    component: () => import("@/views/Meeting/ShareView.vue"),
  },
];

const settingRoutes = [
  {
    path: "main",
    name: "setting-main",
    component: () => import("@/views/Setting/MainView.vue"),
  },
  {
    path: "video",
    name: "setting-video",
    component: () => import("@/views/Setting/VideoView.vue"),
  },
  {
    path: "audio",
    name: "setting-audio",
    component: () => import("@/views/Setting/AudioView.vue"),
  },
  {
    path: "record",
    name: "setting-record",
    component: () => import("@/views/Setting/RecordView.vue"),
  },
  {
    path: "auth",
    name: "setting-auth",
    component: () => import("@/views/Setting/AuthView.vue"),
  },
  {
    path: "about",
    name: "setting-about",
    component: () => import("@/views/Setting/AboutView.vue"),
  },
];

const routes = [
  {
    path: "/auth",
    redirect: "/auth/login", // 直接重定向到 /auth/login
    meta: { requiresAuth: false },
    children: authRoutes,
  },
  {
    path: "/",
    redirect: "/meeting",
    component: HomeView,
    meta: { requiresAuth: true },
    children: [
      {
        path: "meeting",
        name: "meeting",
        component: () => import("@/views/Meeting/MeetingLayoutView.vue"),
        redirect: "/meeting/main",
        children: meetingRoutes,
      },
      {
        path: "record",
        name: "record",
        component: () => import("@/views/Record/ListView.vue"),
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("@/views/Setting/SettingLayoutView.vue"),
        redirect: "/setting/main",
        children: settingRoutes,
      },
      {
        path: "user/index",
        name: "user-index",
        component: () => import("@/views/User/IndexView.vue"),
      },
    ],
  },
  {
    path: "/rtc",
    name: "rtc",
    component: () => import("@/views/Rtc/RtcView.vue"),
  },
  {
    path: "/screen/controller",
    name: "screen-controller",
    component: () => import("@/views/Rtc/screenController.vue"),
  },
  {
    path: "/share/white/board",
    name: "share-white-board",
    component: () => import("@/views/Rtc/WhiteBoardView.vue"),
  },
];

const router = createRouter({
  linkExactActiveClass: "exact-active-link",
  history: createWebHashHistory(),
  routes,
});

// 路由守卫
router.beforeEach((to, from, next) => {
  const userStore = useAuthStore();
  const { token, userInfo, boxResetNickname } = userStore;

  // 防止重定向循环
  if (["auth-login", "auth-register", "auth-box", "auth-admin"].includes(to.name)) {
    console.log(111);
    if (to.name !== "auth-admin") {
      if (window.electronAPI) {
        return next();
      } else {
        return next({ name: "auth-admin" });
      }
    }
  }

  // 允许盒子账号不选择诊室就可以进入的页面
  const boxAccountAllowedRoutes = ["rtc", "screen-controller", "share-white-board"];
  if (boxAccountAllowedRoutes.includes(to.name) && userInfo && userInfo.group_name === "盒子账号" && !boxResetNickname) {
    return next();
  }

  // 需要认证的页面且用户未认证，重定向到登录页
  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    if (window.electronAPI) {
      return next({ name: "auth-login" });
    } else {
      return next({ name: "auth-admin" });
    }
  }

  // 检查盒子账号是否已设置诊室
  if (!userInfo || (!boxResetNickname && userInfo.group_name === "盒子账号")) {
    console.log("登录的是盒子账户，并且没有设置诊室");
    return next({ name: "auth-box" });
  }

  // 默认允许访问
  next();
});

export default router;
