import * as API from "@/api/api";

const paramType = {
  logoutUser: "/user/logout",
  stop: "/user/stop",
  updateNickname: "/user/update/nickname",
  updatePhone: "/user/update/phone",
  updateAvatar: "/user/update/avatar",
  updatePhoneSendCode: "/user/update/phone/send/code",

  updatePassword: "/user/update/password",
  updatePasswordSendCode: "/user/update/password/send/code",

  authInfo: "/user/auth/info",
  updateAuthStatus: "/user/update/auth/info",

  userInfo: "/user/info",
  userMeetingInfo: "/user/meeting/info",

  updatePreferences: "/user/update/preferences",
};

// 发送 GET 请求的函数
export const userGet = (key, param) => {
  const url = paramType[key];
  return API.get(url, param);
};

// 发送 POST 请求的函数
export const userPost = (key, param) => {
  const url = paramType[key];
  return API.post(url, param);
};

// 上传头像的函数
export const userUpload = (key, formData) => {
  const url = paramType[key];
  return API.uploadImage(url, formData);
};
