import { defineStore } from "pinia";

import { usePreferencesStore } from "@/store/usePreferencesStore";
import { meetingPost } from "@/api/modules/meeting";
import { ElNotification } from "element-plus";
import { h } from "vue";
import eventBus from "@/utils/eventBus";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    token: null,
    userInfo: {
      id: null,
      username: null,
      nickname: null,
      avatar: null,
      company_id: null,
      group_id: null,
      surgeries: [],
      company_name: {
        group_id: null,
      },
      job: null,
      group_name: null,
    },
    boxResetNickname: false,

    intervalId: null, // 用于存储 setInterval 的 ID

    notificationArr: [],
  }),
  actions: {
    login(token, userInfo, preferences) {
      this.token = token;
      this.userInfo = userInfo;

      // 加载用户偏好设置
      const preferencesStore = usePreferencesStore();

      console.log("加载用户偏好设置", preferences);
      preferencesStore.loading(preferences);
    },
    logout() {
      console.log("调用了退出方法");
      this.stopFetchingData();

      this.$reset();

      // 重置用户偏好设置
      const preferencesStore = usePreferencesStore();
      preferencesStore.$reset();
    },
    fetchData() {
      // 调用接口
      console.log("调用接口 进行测试是否有即将参加的会议");
      meetingPost("soonStartMeeting").then((res) => {
        if (res.status) {
          if (res.data) {
            // 判断一下是否通知过了
            if (this.notificationArr.find((meeting) => meeting.roomId === res.data.meeting_number)) {
              return false;
            }

            console.log("将要参展的会议", res.data);
            this.notificationArr.push({
              roomId: res.data.meeting_number,
            });
            // 保存通知实例
            const notificationInstance = ElNotification({
              duration: 0,
              position: "bottom-right",
              title: "您参加的会议即将开始",
              dangerouslyUseHTMLString: false,
              message: h("div", { class: "q-w-[19em]" }, [
                h("div", { class: "q-text-base q-font-medium q-text-gray-900" }, res.data.title),
                h("div", { class: "q-text-xs q-text-gray-700 q-mt-2" }, `会议时间：${res.data.start_time}`),
                h("div", { class: "q-text-xs q-text-gray-700 q-mt-2" }, `主持人：${res.data.launch_user.nickname}`),
                h("div", { class: "q-flex q-items-center q-justify-end q-mt-2" }, [
                  h(
                    "button",
                    {
                      class: "q-bg-gray-500 q-text-white q-font-bold q-py-1 q-px-2 q-rounded q-text-xs q-text-gray-50",
                      onClick: () => {
                        notificationInstance.close(); // 关闭通知
                      },
                    },
                    "暂不进入",
                  ),
                  h(
                    "button",
                    {
                      class: "q-bg-green-621 q-text-white q-font-bold q-py-1 q-px-2 q-rounded q-text-xs q-ml-2",
                      onClick: () => {
                        // 处理立即入会的逻辑

                        eventBus.emit("to-join-page", res.data.meeting_number);
                        notificationInstance.close(); // 可以选择在进入会议后关闭通知
                      },
                    },
                    "立即入会",
                  ),
                ]),
              ]),
            });
          }
        }
      });
    },
    startFetchingData() {
      if (this.intervalId) {
        return false;
      }
      this.fetchData(); // 第一次立即调用
      this.intervalId = setInterval(this.fetchData, 5000); // 每两秒调用一次
    },
    stopFetchingData() {
      if (this.intervalId) {
        clearInterval(this.intervalId); // 清除计时器
        this.intervalId = null;
      }
    },
  },
  // 持久化配置
  persist: {
    enabled: true,
    strategies: [
      {
        key: "user",
        storage: localStorage, // 使用 localStorage 进行持久化
        paths: ["token", "userInfo"],
      },
    ],
  },
});
