import http from "@/utils/request";

// 发送 GET 请求的函数
export const get = (url, params) => {
  return http.request({
    url,
    method: "GET",
    params,
  });
};

// 发送 POST 请求的函数
export const post = (url, data) => {
  return http.request({
    url,
    method: "POST",
    data,
  });
};

// 修改上传图片的函数，使其接受一个自定义的URL
export const uploadImage = (url, formData) => {
  return http.request({
    url,
    method: "POST",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data", // 设置正确的Content-Type
    },
  });
};
