import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "q-h-screen q-flex q-flex-col q-pt-5 q-items-center q-text-center"
};
const _hoisted_2 = {
  class: ""
};
const _hoisted_3 = ["src"];
const _hoisted_4 = {
  class: "q-mt-4 q-text-sm q-text-gray-600 q-font-semibold q-w-12 q-overflow-hidden q-truncate"
};
const _hoisted_5 = {
  class: "q-space-y-6 q-mt-4 q-text-gray-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_link, {
    to: "/user/index",
    class: "q-block"
  }, {
    default: _withCtx(() => [_createElementVNode("img", {
      src: $setup.store.userInfo.avatar === null ? 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png' : $setup.store.userInfo.avatar,
      alt: "头像",
      class: "q-rounded-full q-w-12 q-h-12"
    }, null, 8, _hoisted_3)]),
    _: 1
  }), _createElementVNode("p", _hoisted_4, _toDisplayString($setup.store.userInfo.nickname), 1)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_router_link, {
    to: "/meeting",
    class: _normalizeClass(["q-block", {
      'exact-active-link': _ctx.$route.fullPath.includes('/meeting')
    }])
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createElementVNode("div", null, [_createElementVNode("span", {
      class: "iconfont icon-huiyi q-text-3xl"
    }), _createElementVNode("p", {
      class: "q-text-xs q-mt-1"
    }, "会议")], -1)])),
    _: 1
  }, 8, ["class"]), $setup.store.userInfo.group_id !== 4 ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [$setup.store.userInfo.group_name !== '会员账号' ? (_openBlock(), _createBlock(_component_router_link, {
    key: 0,
    to: "/record",
    class: "q-block"
  }, {
    default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", null, [_createElementVNode("span", {
      class: "iconfont icon-luxiang q-text-3xl"
    }), _createElementVNode("p", {
      class: "q-text-xs q-mt-1"
    }, "录像")], -1)])),
    _: 1
  })) : _createCommentVNode("", true), _createVNode(_component_router_link, {
    to: "/setting",
    class: _normalizeClass(["q-block", {
      'exact-active-link': _ctx.$route.fullPath.includes('/setting')
    }])
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", null, [_createElementVNode("span", {
      class: "iconfont icon-shezhi-shezhi-tianchong q-text-3xl"
    }), _createElementVNode("p", {
      class: "q-text-xs q-mt-1"
    }, "设置")], -1)])),
    _: 1
  }, 8, ["class"])], 64)) : _createCommentVNode("", true)])])])]);
}