import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";

import router from "@/router";
import { useAuthStore } from "@/store/useAuthStore"; // 确保你有一个router实例并正确地导入
// 获取环境变量并设置 baseURL
let baseURL;
if (window.electronAPI) {
  baseURL = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_BASE_URL_DEV : process.env.VUE_APP_API_BASE_URL_PROD;
} else {
  baseURL = process.env.NODE_ENV === "development" ? process.env.VUE_APP_API_BASE_URL_DEV : process.env.VUE_APP_API_BASE_URL_ADMIN_PROD;
}

class HttpRequest {
  constructor() {
    this.instance = axios.create({
      // baseURL: "https://api.yubo.lingyaoling.cn/api", // 所有请求地址的前缀部分（没有后端请求不用写）
      // baseURL: "/api", // 所有请求地址的前缀部分（没有后端请求不用写）
      baseURL: baseURL, // 使用环境变量的 baseURL
      timeout: 80000, // 请求超时时间（毫秒）
      withCredentials: true, // 异步请求携带 cookie
      headers: {
        // 设置后端需要的传参类型
        "Content-Type": "application/json",
      },
    });

    this.isAlertShown = false; // 添加一个标志位

    // 请求拦截
    this.instance.interceptors.request.use(
      (config) => {
        // 添加全局的 loading..
        // 请求头携带 token
        const user = JSON.parse(localStorage.getItem("user"));
        if (user && user.token) {
          config.headers["Authorization"] = "Bearer " + user.token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // 响应拦截
    this.instance.interceptors.response.use(
      (response) => {
        // 1006 代表已在token失效
        if (!response.data.status && response.data.code === 1006) {
          // if (!this.isAlertShown) {
          //   this.isAlertShown = true; // 先设置标志位，防止重复调用弹窗
          //   ElMessageBox.alert('当前登录已失效，请重新登录', "提示", {
          //     showClose: false,
          //     confirmButtonText: "确定",
          //     type: "warning",
          //   }).then(() => {
          //     if (this.isAlertShown) {
          //       // 在回调内再进行检查
          //       localStorage.removeItem("user");
          //       router.push("/auth");
          //       this.isAlertShown = false; // 重置标志位
          //
          //       // 直接强制关闭rtc窗口
          //       if (window.electronAPI) {
          //         window.electronAPI.forceCloseRtcWindow();
          //       }
          //
          //       // 关闭监听
          //       const authStore = useAuthStore();
          //       authStore.stopFetchingData();
          //     }
          //   });
          // }

          const authStore = useAuthStore();
          authStore.stopFetchingData();

          localStorage.removeItem("user"); // 清除token
          router.push("/auth"); // 跳转到登录页
          return false;
        }

        // 1004 代表已在其他设备登录 是否登录
        if (!response.data.status && response.data.code === 1004) {
          // 响应数据统一处理
          return response.data;
        }

        // 1007 代表已在其他设备登录 强制用户退出
        if (!response.data.status && response.data.code === 1007) {
          // 关闭监听
          const authStore = useAuthStore();
          authStore.stopFetchingData();

          if (!this.isAlertShown && authStore.token) {
            this.isAlertShown = true; // 先设置标志位，防止重复调用弹窗
            ElMessageBox.alert(response.data.message, "提示", {
              showClose: false,
              confirmButtonText: "确定",
              type: "warning",
            }).then(() => {
              if (this.isAlertShown) {
                // 在回调内再进行检查

                this.isAlertShown = false; // 重置标志位

                authStore.logout();
                router.push({ name: "auth-login" });
              }
            });
          }

          // 直接强制关闭rtc窗口
          if (window.electronAPI) {
            window.electronAPI.forceCloseRtcWindow();
          }
          return false;
        }

        if (response.data.code === 422) {
          // 处理验证错误，显示第一个错误消息
          const errors = response.data.data;
          const firstErrorField = Object.keys(errors)[0];
          const firstErrorMessage = errors[firstErrorField][0];
          ElMessage.error(firstErrorMessage);
          return false; // 阻止进一步的处理
        }

        if (!response.data.status) {
          if (response.data.message !== "noMessage") {
            ElMessage.error(response.data.message);
          }
          return false;
        }
        // 响应数据统一处理
        return response.data;
      },
      (error) => {
        console.log("error==>", error);
        return Promise.reject(error);
      },
    );
  }

  // 发送请求的方法
  request(options) {
    return new Promise((resolve, reject) => {
      this.instance
        .request(options)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          // 统一处理请求异常
          reject(error);
        });
    });
  }
}

// 导出单例对象
const http = new HttpRequest();
export default http;
