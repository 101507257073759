import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import piniaPluginPersist from "pinia-plugin-persist";
if (window.electronAPI) {
  // 保存原始的 console 方法
  const originalConsoleMethods = {
    log: window.console.log,
    info: window.console.info,
    warn: window.console.warn,
    error: window.console.error,
  };

  // 防止重复调用的标志
  let isLogging = false;

  // 创建一个通用的替换函数
  const replaceConsoleMethod = (method) => {
    window.console[method] = (...args) => {
      if (isLogging) {
        // 如果正在处理日志，直接调用原始方法以避免循环
        originalConsoleMethods[method](...args);
        return;
      }

      try {
        isLogging = true; // 设置标志，表示正在处理日志

        // 尝试将每个参数转换为可以序列化的形式
        const serializableArgs = args.map((arg) => {
          try {
            return JSON.stringify(arg); // 尝试序列化对象
          } catch {
            return String(arg); // 如果不能序列化，转换为字符串
          }
        });

        // 调用对应的 electronAPI 方法
        window.electronAPI[method](...serializableArgs);
      } catch (e) {
        // 如果出现错误，打印错误信息到原始 console
        originalConsoleMethods.error("无法克隆对象:", e);
      } finally {
        isLogging = false; // 重置标志
      }

      // 同时调用原始的 console 方法
      originalConsoleMethods[method](...args);
    };
  };

  // 替换 console 方法
  ["log", "info", "warn", "error"].forEach(replaceConsoleMethod);
}
/**
 * 引入iconfont
 */
import "@/assets/iconfont/iconfont.css";

/**
 * 引入css
 */
import "element-plus/dist/index.css";
import "@/assets/css/tailwind.css";
import "@/assets/css/element.scss";

/**
 * 引入基础信息
 */
const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersist);

app.use(pinia);
app.use(router);
app.mount("#app");
