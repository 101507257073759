import "core-js/modules/es.array.push.js";
import ContainerAside from "@/components/Container/ContainerAside.vue";
import { useAuthStore } from "@/store/useAuthStore";
import { userPost } from "@/api/modules/user";
import { useDefaultDeviceStore } from "@/store/useDefaultDeviceStore";
import { usePreferencesStore, watchPreferencesStore } from "@/store/usePreferencesStore";
import eventBus from "@/utils/eventBus";
export default {
  setup() {
    const defaultDeviceStore = useDefaultDeviceStore();
    const preferences = usePreferencesStore();
    defaultDeviceStore.init();
    console.log("初始化设备");
    defaultDeviceStore.initDevice();
    return {
      preferences
    };
  },
  name: "HomeView",
  components: {
    ContainerAside
  },
  mounted() {
    this.getUserInfo();

    // 监听加入会议的按钮
    eventBus.on("to-join-page", this.toJoinPage);

    // 使用从 preload.js 暴露的 API
    if (window.electronAPI) {
      window.electronAPI.onSetDefaultPath((event, defaultPath) => {
        console.log("defaultPath 111", defaultPath);
        console.log("this.preferences.record_path", this.preferences.record_path);
        console.log("this.preferences.record_path", !this.preferences.record_path);
        if (!this.preferences.record_path) {
          this.preferences.setDefaultFilePath(defaultPath);
        }
      });
      window.electronAPI.onSetRecordPath((event, path) => {
        console.log("setRecordPath 主线程发送的消息 path 值为", path);
      });
      window.electronAPI.receive("set-test", data => {
        console.log("setRecordPath 主线程发送的消息 data 值为", data);
      });

      // 自启相关

      // 请求当前的自启动状态
      window.electronAPI.requestAutoLaunchStatus();
      // 监听自启动状态变化
      window.electronAPI.onAutoLaunchStatus(status => {
        this.preferences.power_on = status ? 1 : 0;
      });
    }
    watchPreferencesStore();
  },
  methods: {
    toJoinPage(room_id) {
      this.$router.push({
        name: "join",
        // 使用路由的名字
        query: {
          room_id: room_id // 传递 room_id 作为查询参数
        }
      });
    },
    getUserInfo() {
      const store = useAuthStore();
      this.userInfo = store.userInfo;
      if (this.userInfo.group_id !== 2) {
        // 修改缓存内容（用来快速展示  要不然就页面闪烁 ）
        userPost("userInfo", {}).then(res => {
          if (res.status) {
            const store = useAuthStore();
            store.userInfo = res.data;
            this.userInfo = store.userInfo;
          }
        });
      }
    }
  },
  created() {
    if (window.electronAPI) {
      window.electronAPI.homePages();
    }
  }
};