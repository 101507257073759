import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "q-flex q-items-end q-justify-center q-mt-4 q-mb-6"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_router_view), _createVNode(_component_el_dialog, {
    modelValue: $data.dialogStatus,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.dialogStatus = $event),
    top: "25vh",
    title: "版本更新"
  }, {
    default: _withCtx(() => [_cache[4] || (_cache[4] = _createElementVNode("div", {
      class: "q-p-6 q-text-center"
    }, "有新的版本可以更新，请点击下载更新！", -1)), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_button, {
      size: "small",
      class: "q-w-20",
      onClick: _cache[0] || (_cache[0] = $event => $data.dialogStatus = false)
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode(" 暂不更新 ")])),
      _: 1
    }), _createVNode(_component_el_button, {
      size: "small",
      type: "primary",
      class: "q-w-20",
      onClick: $options.goDownload
    }, {
      default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode(" 下载更新 ")])),
      _: 1
    }, 8, ["onClick"])])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}