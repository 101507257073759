import { defineStore } from "pinia";
import { watch } from "vue"; // 需要引入 watch 来监听 state

export const usePreferencesStore = defineStore("preferences", {
  state: () => ({
    power_on: 0,
    hd_video: 0,
    auto_start_video: 0,
    auto_start_audio: 0,
    locking_not_meeting: 0,
    meeting_number: "",
    video_mirror: 0,
    video_clarity: 0,
    auto_start_record: 0,
    record_path: "",
  }),
  actions: {
    updateFilePath(newPath) {
      this.record_path = newPath;
    },
    setDefaultFilePath(defaultPath) {
      this.record_path = defaultPath;
    },
    loading(data) {
      this.power_on = data.power_on;
      this.hd_video = data.hd_video;

      this.auto_start_video = data.auto_start_video;
      this.auto_start_audio = data.auto_start_audio;
      this.locking_not_meeting = data.locking_not_meeting;

      this.meeting_number = data.meeting_number;
      this.video_mirror = data.video_mirror;
      this.video_clarity = data.video_clarity;
      this.auto_start_record = data.auto_start_record;
    },
  },
  // 持久化配置
  persist: {
    enabled: true,
    strategies: [
      {
        key: "preferences",
        storage: localStorage, // 使用 localStorage 进行持久化
      },
    ],
  },
});

// 监听 store 中 locking_not_meeting 的变化
export function watchPreferencesStore() {
  const store = usePreferencesStore();

  watch(
    () => store.locking_not_meeting,
    (newValue) => {
      window.electronAPI.storeSet("locking_not_meeting", newValue).then(() => {
        console.log("locking_not_meeting 已更新为:", newValue);
      });
    },
  );
}
