import { defineStore } from "pinia";

export const useVersionStore = defineStore("version", {
  state: () => ({
    version: "",
  }),
  // 持久化配置
  persist: {
    enabled: true,
    strategies: [
      {
        key: "version",
        storage: localStorage, // 使用 localStorage 进行持久化
      },
    ],
  },
});
