import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "common-layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_container_aside = _resolveComponent("container-aside");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, null, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      width: "80px"
    }, {
      default: _withCtx(() => [_createVNode(_component_container_aside)]),
      _: 1
    }), _createVNode(_component_el_main, {
      class: "gradient-bg"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  })]);
}